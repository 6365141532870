import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'
import axios from 'axios';

dayjs.extend(utc)
dayjs.extend(timezone)

const baseUrl = 'https://api.sahkonhinta.info/public/spot_prices/'

function median(data) {
  if (data.length % 2 === 0) {
    const i1 = Math.floor(data.length / 2)
    const i2 = Math.ceil(data.length / 2)
    return ((parseFloat(data.at(i1)) + parseFloat(data.at(i2))) / 2)
  } else {
    return (parseFloat(data.at(Math.ceil(data.length / 2))))
  }
}

function getAggStats(data) {
  const s = [...data].sort((first, second) => parseFloat(first.price_c_kwh) < parseFloat(second.price_c_kwh) ? -1 : 1)
  return {
    median: median(s.map(dp => dp.price_c_kwh)),
    lowPrice: { ts: s.at(0).start_timestamp, price: s.at(0).price_c_kwh },
    highPrice: { ts: s.at(-1).start_timestamp, price: s.at(-1).price_c_kwh },
    thresholds: { low: s.at(5).price_c_kwh, high: s.at(-6).price_c_kwh }
  }
}

function getBin(val, { low, high }) {
  return val <= low ? 1 : val < high ? 2 : 3
}

async function getDataForDate(date) {

  const start = dayjs.tz(date, 'Europe/Helsinki').startOf('D').utc().format()
  const end = dayjs.tz(date, 'Europe/Helsinki').endOf('D').utc().format()

  const params = {
    start, end
  }

  let res = null
  try {
    res = await axios.get(baseUrl, { params })
  }
  catch (e) {
    return null
  }

  const { data } = res.data

  if (!data || data.length < 24) {
    return null
  }

  const aggStats = getAggStats(data)

  return (
    {
      stats: {
        median: aggStats.median,
        lowPrice: { price: parseFloat(aggStats.lowPrice.price), hour: dayjs(aggStats.lowPrice.ts).tz('Europe/Helsinki').format('HH:mm') },
        highPrice: { price: parseFloat(aggStats.highPrice.price), hour: dayjs(aggStats.highPrice.ts).tz('Europe/Helsinki').format('HH:mm') },
      },
      hours: (
        data
          .map(dp => ({
            hour: dayjs(dp.start_timestamp).tz('Europe/Helsinki').format('HH:mm'),
            price: parseFloat(dp.price_c_kwh),
            bin: getBin(parseFloat(dp.price_c_kwh), aggStats.thresholds)
          }))
          .sort((first, second) => (
            first.hour < second.hour ? -1 : 1
          ))
      )
    }
  )

};

export default { getDataForDate }
