import styled, { css, keyframes } from "styled-components";

const PageTitle = styled.h2`
  padding-top: 1rem;
  font-size: 1.4rem;
  margin-block-end: 0.25rem;
  text-align: center;
`
const Button = styled.button`
  background: #6161D2;
  background: -webkit-linear-gradient(top right, #6161D2, #3F3FB2);
  background: -moz-linear-gradient(top right, #6161D2, #3F3FB2);
  background: linear-gradient(to bottom left, #6161D2, #3F3FB2);
  padding: 7px 20px;
  border: 1px solid rgb(52 52 156);
  border-radius: 3px;
  text-decoration: none;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Fira Sans', Arial, Helvetica, sans-serif;
  cursor: pointer;
  margin-block-start: 0.75rem;
  margin-block-end: 0.75rem;

  @media (hover:hover) {
    &:hover {
      filter: brightness(85%);
    }
  }
`
const DangerButton = styled(Button)`
  background: rgb(176,2,2);
  background: linear-gradient(45deg, rgba(176,2,2,1) 0%, rgba(198,0,0,1) 100%);
  border: 1px solid rgb(154 0 0);
`

const MultiSwitchGroup = styled.div`
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
`

const MultiSwitchLabel = styled.span`
  font-size: 1rem;
  font-weight: 500;
  margin-right: 1rem;
  text-align: left;

`

const MultiSwitchButtonGroup = styled.span`
  :first-child {
    margin-left: none;
  }
  :last-child {
    margin-right: none;
  }
`

const MultiSwitchButton = styled.button`
  font-family: 'Fira Sans', Arial, Helvetica, sans-serif;
  border-radius: 1000px;
  cursor: pointer;
  color: #282828;
  font-weight: 400;
  line-height: 1;
  margin: 0 2px;
  padding: 0.3em 0.9em;
  min-width: 30px;
  text-align: center;
  white-space: nowrap;
  //width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  background: none;
  font-size: 1rem;

  &:hover {
    background: #e5e5ea;
  }

  ${p => p.disabled && css`
    background: transparent;
    color: #c7c7cc;
    text-decoration: line-through;
    &:hover {
      background: transparent;
      cursor: default;
    }
  `}
  
  ${p => p.selected && css`
    background: ${p => p.off ? 'hsl(210, 8%, 45%)' : '#6161D2'};
    /* box-shadow: 0 0 0 var(--su-static4) var(--_ts-bs-color); */
    color: white;
    font-weight: 500;

    &:hover {
      background: ${p => p.off ? 'hsl(210, 8%, 45%)' : '#6161D2'};
    }

  `}
`

const LoaderSpin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  border: 24px solid #e5e5ea;
  border-top: 24px solid #6161D2;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${LoaderSpin} 1.5s cubic-bezier(0.77, -0.44, 0.32, 1.36) infinite;
  animation-delay: -0.5s;
`

export { PageTitle, Button, DangerButton, MultiSwitchButton, MultiSwitchGroup, MultiSwitchLabel, MultiSwitchButtonGroup, Loader }

