import { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Button } from './CommonStyledComponents'
import userService from '../services/users'


const FormTitle = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
`

const FormField = styled.input`
  display: block;
  box-sizing: border-box;
  height: auto;
  margin: auto;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ced4da;
  border-radius: 0;
  margin-bottom: -1px;
  width: 100%;
  color: #495057;
  position: relative;

  ${p => p.invalid && css`
    z-index: 2;  
    border-color: hsl(1, 42%, 49%);
    outline: 0;
  `}

  &:focus {
    z-index: 2;
    border-color: #6161D2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #6161d24d;
  }
  &:autofill,
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    background: white !important;
    box-shadow: 0 0 0 30px white inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
`

const LoginButton = styled(Button)`
  width: 100%;
  height: 40px;
  margin-top: 20px;
`

const FormContainer = styled.div`
  max-width: 300px;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  text-align: center;
  background: white;
  border: 1px solid #cfcfe6;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 3px;

  ${FormField}:first-of-type {
    margin-top: 20px;
    border-radius: 3px 3px 0 0;
  }

  ${FormField}:last-of-type {
    border-radius: 0 0 3px 3px;
  }
`

const FormToggleText = styled.span`
  font-size: 0.75rem;

  a {

  }
`

const ValidationErrorMsg = styled.p`
  margin-top: 10px;
  margin-bottom: 0;
  color: hsl(1, 42%, 49%);
  font-weight: 500;
  font-size: 0.75rem;
`

const RegisterForm = ({ notifyFunc }) => {
  const navigate = useNavigate()

  const defaultValue = { email: '', password: '',  password_confirm: '' }
  const [credentials, setCredentials] = useState(defaultValue)

  const emailIsOk = () => (credentials.email.length === 0 || !!credentials.email.match(/.+@.+\.\D+/))
  const passwordIsLongEnough = () => (credentials.password.length === 0 || credentials.password.length >= 8)
  const passwordIsOk = () => (credentials.password.length === 0 || (!!credentials.password.match(/\d/) && !!credentials.password.match(/[^a-zA-Z0-9]/)))
  const passwordsDoMatch = () => (credentials.password_confirm.length == 0 || credentials.password === credentials.password_confirm)
  
  const handleFormChange = ({ target }) => {
    setCredentials({ ...credentials, [target.id]: target.value });
  }

  const handleUserCreate = async (event) => {
    event.preventDefault()
    try {
      await userService.createUser({ email: credentials.email, password: credentials.password })
      navigate('/emailConfirmPrompt')
    } catch (e) {
      notifyFunc('Tilin luonti epäonnistui')
      console.log(e)
    }
  }

  return (
    <>
      <FormContainer>
        <FormTitle>Luo tili</FormTitle>
        <form>
          <FormField id='email' type='email' placeholder='Sähköpostiosoite' required onChange={handleFormChange} invalid={!emailIsOk()} />
          <FormField id='password' type='password' placeholder='Salasana' required onChange={handleFormChange} invalid={!passwordIsLongEnough() && !passwordIsOk()} />
          <FormField id='password_confirm' type='password' placeholder='Salasana uudelleen' required onChange={handleFormChange} invalid={!passwordsDoMatch()} />
          {emailIsOk() || <ValidationErrorMsg>Sähköpostiosoite ei kelpaa</ValidationErrorMsg>}
          {passwordIsLongEnough() || <ValidationErrorMsg>Salasanan on oltava vähintään 8 merkin pituinen</ValidationErrorMsg>}
          {passwordIsOk() || <ValidationErrorMsg>Salasanassa on oltava vähintään yksi numero ja yksi erikoismerkki</ValidationErrorMsg>}
          {passwordsDoMatch() || <ValidationErrorMsg>Salasanat eivät täsmää</ValidationErrorMsg>}
          <LoginButton
            onClick={(emailIsOk() && passwordsDoMatch()) ? handleUserCreate : e => e.preventDefault()}
          >
            Luo tili
          </LoginButton>
        </form>
      <FormToggleText>
        Onko sinulla jo tili? <Link to='/login'>Kirjaudu sisään</Link>
      </FormToggleText>
      </FormContainer>
    </>
  )
}


const LoginForm = ({ onLogin, notifyFunc }) => {
  const navigate = useNavigate()
  const { search } = useLocation()

  useEffect(() => {
    search.includes('emailConfirmed') && notifyFunc('Sähköpostin varmistus onnistui')
  }, [])

  const defaultValue = { email: '', password: '' }
  const [credentials, setCredentials] = useState(defaultValue)

  const handleFormChange = ({ target }) => {
    setCredentials({ ...credentials, [target.id]: target.value })
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    const login = await onLogin(credentials)
    if (login) {
      setCredentials(defaultValue)
      navigate('/')
    }
  }

  return (
    <>
      <FormContainer>
        <FormTitle>Kirjaudu sisään</FormTitle>
        <form>
          <FormField id='email' type='email' placeholder='Sähköpostiosoite' required onChange={handleFormChange} />
          <FormField id='password' type='password' placeholder='Salasana' required onChange={handleFormChange} />
          <LoginButton onClick={handleLogin}>Kirjaudu</LoginButton>
        </form>
        <FormToggleText>
          Täällä ensi kertaa? <Link to='/register'>Luo tili</Link>
        </FormToggleText>
      </FormContainer>
    </>
  )
}

export { LoginForm, RegisterForm }
