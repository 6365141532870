import axios from 'axios'

const baseUrl = 'https://api.sahkonhinta.info/users/'

const createUser = async (newUser) => {
  const res = await axios.post(baseUrl, newUser)
  return res.data
}

const deleteUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(baseUrl + userId, config)
  return res.status === 204 ? true : false
}

const editUser = async (userId, modifiedUser, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(modifiedUser)
  const res = await axios.patch(baseUrl + userId + '/', modifiedUser, config)
  return res.data
}

const subscribe = async (subscribe, userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };
  
  const res = await axios.post(`${baseUrl}${userId}/subscription/`, {}, {...config, params: { subscribe } })
  return res.data
}

export default { createUser, editUser, deleteUser, subscribe }
