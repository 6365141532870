import { useState } from 'react'
import styled, { css,  keyframes } from 'styled-components'


const BarChartTooltipDiv = styled.div`
  ${p => p.visible
    ? css`
      visibility: visible;
      opacity: 1;
      transition: opacity 0.1s linear;
    `
    : css`
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.1s, opacity 0.1s linear;
    `};

  position: absolute;
  left: ${p => p.posX}%;
  bottom: ${p => p.posY}%;
  transform: translateX(-23px);
  margin-bottom: 40px;
  text-align: center;
  height: 50px;
  width: 75px;
  border-radius: 5px;
  background: #141414e0;
  color: white;
  font-size: 0.9rem;
  z-index: 999;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #141414e0 transparent transparent transparent;
  }
`

const BarChartTooltipInnerDiv = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  margin: auto;
  transform: translateY(-50%);

`

function BarChartTooltip({ data: { hour, price }, position: { x, y, visible } }) {
  return (
  <BarChartTooltipDiv visible={visible} posX={x} posY={y}>
    <BarChartTooltipInnerDiv>
      <span style={{ display: 'block', marginBottom: '3px' }}>{hour}</span>
      <span style={{ display: 'block' }}>{price}&nbsp;c</span>
    </BarChartTooltipInnerDiv>
  </BarChartTooltipDiv>
)
}

const BarCharLabelDiv = styled.div`
  color: hsl(210,8%,45%);
  position: absolute;
  left: calc(4.2% * ${p => p.i});
  bottom: 0;
  z-index: 9;
  width: 3.5%;
  text-align: center;
`

const BarChartLabelContainer = styled.div`

  @media (max-width: 800px) {
    margin-top: -0.75rem;
    ${BarCharLabelDiv}:nth-of-type(even) {
      display: none;
    }
  }

`


function BarChartLabel({ hour }) {
  return (
    <BarCharLabelDiv i={hour} >
      {hour.toString().padStart(2, '0')}
    </BarCharLabelDiv>
  )
}

const BarChartContainer = styled.div`
  position: absolute;
  left: 20px;
  bottom: 15px;
  top: 80px;
  right: 20px;

  @media (max-width: 800px) {
    top: 100px;
  }
`

function BarKf(height) {
  return (
    keyframes`
      to {
        height: ${height}px;
      }`
  )
}

const Bar = styled.rect`
  height: 0;
  cursor: pointer;

  animation: ${p => BarKf(p.height)} 0.8s forwards;
  animation-delay: ${p => p.index * 0.01 + 0.1}s;

  fill: ${p => p.greyed ? 'rgb(229, 229, 234)' : p.color};
  z-index: 10;

  &:hover {
    filter: brightness(0.95);
  }
`

const BarChartVerticalRef = styled.line`
  stroke-width: 0.5px;
  stroke-dasharray: 1 0.75;
  transform: translateX(1.75%);
  visibility: ${p => p.visible ? 'visible': 'hidden'};
  mix-blend-mode: plus-lighter;
  stroke: #7a7a7a54;;
  pointer-events: none;
  opacity: 0;

  animation: ${keyframes`
    to { opacity: 1 }
  `} 0.5s forwards;
  animation-delay: 0.5s;
`


function BarChart({ data, maxPrice, indexNow, selectedHourIndex, setSelectedHourIndex, resetSelectedHourIndex, today, date }) {
  const [tooltipPos, setTooltipPos] = useState({ visible: false, x: 0, y: 0})
  const [tooltipData, setTooltipData] = useState({ })
  const [highlight, setHighlight] = useState(false)

  function toggleHighlight(i) {
    if (i === undefined || selectedHourIndex === i && highlight) {
      resetSelectedHourIndex()
      setHighlight(false)
    } else {
      setSelectedHourIndex(i)
      setHighlight(true)
    }
  }

  const colorsByBin = {
    1: 'rgb(48, 209, 88)',
    2: 'rgb(255, 159, 10)',
    3: 'rgb(255, 69, 58)',
  }

  return (
    <BarChartContainer onClick={() => toggleHighlight()}>
      
      <BarChartTooltip data={tooltipData} position={tooltipPos} />
      
      <BarChartLabelContainer>
        {[ ...Array(24).keys() ].map(h => (
          <BarChartLabel hour={h} key={h} />
        ))}
      </BarChartLabelContainer>
      
      <svg className='chart' style={{ transform: 'scaleY(-1)' }} height="100%" width="100%" viewBox='0 0 160 100' preserveAspectRatio='none' role="img">
        {data.map((d, i) => (
          <Bar
            onClick={(e) => {
              e.stopPropagation()
              toggleHighlight(i)
            }}
            greyed={(highlight && selectedHourIndex !== i) || today && (!highlight && i < indexNow)}
            onMouseOver={() => {
              if (window.matchMedia('(hover: hover)')) {
                setTooltipPos({ visible: true, x: (4.2 * i), y: 100 * (d.price / maxPrice)})
                setTooltipData({ hour: d.hour, price: d.grossPrice.toFixed(2) })
              }
            }}
            onMouseLeave={() => {
              setTooltipPos({ ...tooltipPos, visible: false }) 
            }}
            y={5}
            x={(4.2 * i) + '%'} 
            rx='1%'
            width='3.5%'
            height={100 * (d.price / maxPrice)}
            color={colorsByBin[d.bin]}
            index={i}
            data-index={i}
            key={i}
            />
          ))}
          { today && <BarChartVerticalRef visible={!highlight} x1={indexNow * 4.2 + '%'} x2={indexNow * 4.2 + '%'} y1={5} y2={99} /> }
      </svg>
    </BarChartContainer>

  )

}

export default BarChart
