import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Bolt, Login, PersonAdd, Logout, Settings, TrendingUp, Menu } from '@styled-icons/material'


const NavbarDiv = styled.div`
  min-height: 50px;
  background: #6161D2;
  display: flex;
  align-items: center;
  margin: 0 -10px;
  padding: 0 1rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 10;
  
  @media only screen and (max-width: 560px) {
    //display: none;
    height: fit-content;
    position: sticky;
  }
`;

const NavbarContent = styled.div`
  margin: 0;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  z-index: 11;

  a {
    text-decoration: none !important;
  }
`

const LogoContainer = styled.div`
  float: left;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;

  @media only screen and (max-width: 560px) {
    position: absolute;
    top: 15px;
    left: 10px;
    float: none;
  }
`

const LogoText = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  cursor: default;
`

const MenuContainer = styled.div`
  float: right;

  @media only screen and (max-width: 560px) {
    display: ${props => props.show ? 'block' : 'none'};
    position: absolute;
    background: #6161D2;
    top: 50px;
    left: 0;
    right: 0;
    float: none;
    padding: 1rem;
    margin-top: -1px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    z-index: 12;
    border-top: 1px solid white;
  }
`

const OpenButtonContainer = styled.div`
  display: none;
  
  @media only screen and (max-width: 560px) {
    display: block;
    float: right;
    padding: 10px;
    margin-right: -10px;
    cursor: pointer;
    z-index: 13;
  }
`

const OpenButton = styled(Menu)`
  color: white;
`

const BgWrapper = styled.div`
  display: ${props => props.show ? 'block' : 'none'};
  background: black;
  opacity: 45%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  @media (min-width: 560px) {
    display: none;
  }
`

const MenuItem = styled.span`
  color: white;
  margin: 0 0.5rem;
  cursor: pointer;
  white-space: nowrap;
  opacity: ${props => props.selected ? '100%' : '75%'};

  @media (hover:hover) {
    &:hover {
      opacity: 100%;
    }
  }

  @media only screen and (max-width: 560px) {
    //display: none;
    display: block;
    text-align: end;
    opacity: 100%;
    
    margin: 5px 0 5px auto;
    padding: 10px 15px;
    width: fit-content;
    background: #5050b5;
    border-radius: 30px;

    background: ${props => props.selected ? '#5151af' : 'none'};
  }
`

const BoltIcon = styled(Bolt)`
  vertical-align: bottom;
  font-size: 1.1rem;
  margin-right: -2px;
  color: white;
`

const HomeIcon = styled(TrendingUp)`
  vertical-align: bottom;
  font-size: 1.1rem;
  margin-right: 4px;
`

const SettingsIcon = styled(Settings)`
  vertical-align: bottom;
  font-size: 1.1rem;
  margin-right: 4px;
`

const LoginIcon = styled(Login)`
  vertical-align: bottom;
  font-size: 1.1rem;
  margin-right: 4px;
`

const RegisterIcon = styled(PersonAdd)`
  vertical-align: bottom;
  font-size: 1.1rem;
  margin-right: 4px;
`

const LogoutIcon = styled(Logout)`
  vertical-align: bottom;
  font-size: 1.1rem;
  margin-right: 4px;
`


const Navbar = (props) => {
  const { pathname } = useLocation()
  const [showCollapsedMenu, setShowCollapsedMenu] = useState(false)

  const toggleCollapsedMenu = () => { setShowCollapsedMenu(!showCollapsedMenu) }

  // close nav when navigating
  useEffect(() => { setShowCollapsedMenu(false) }, [pathname])

  return (
    <>
      <BgWrapper show={showCollapsedMenu} onClick={toggleCollapsedMenu} />
      <NavbarDiv>
        <NavbarContent>
          <LogoContainer>
            <BoltIcon size={23} />
            <LogoText>Sähkönhinta.info</LogoText>
          </LogoContainer>
          <OpenButtonContainer onClick={toggleCollapsedMenu} >
            <OpenButton size={26} />
          </OpenButtonContainer>
          <MenuContainer show={showCollapsedMenu}>
            <Link to='/analytics'>
              <MenuItem selected={pathname === '/'}>
                <HomeIcon size={19}/>
                Koti
              </MenuItem>
            </Link>
          {props.loggedIn &&
            <Link to='/settings'>
              <MenuItem selected={pathname == '/settings'}>
                  <SettingsIcon size={19} />
                  Asetukset
                </MenuItem>
            </Link>
            }
            {props.loggedIn ?
              <>
                <MenuItem onClick={props.onLogout}>
                  <LogoutIcon size={19} />
                  Kirjaudu ulos
                </MenuItem>
              </>
              :
              <>
                <Link to='/login'>
                  <MenuItem selected={pathname === '/login'} onClick={props.disableRegistration}>
                    <LoginIcon size={19} />
                    Kirjaudu sisään
                  </MenuItem>
                </Link>
                <Link to='/register'>
                  <MenuItem selected={pathname === '/register'} onClick={props.enableRegistration}>
                    <RegisterIcon size={19} />
                    Luo tili
                  </MenuItem>
                </Link>
              </>
            }
          </MenuContainer>
        </NavbarContent>
      </NavbarDiv>
    </>
  )
}

export default Navbar
