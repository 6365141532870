import { useState } from 'react'
import styled from 'styled-components'
import { NotificationsOff, NotificationsActive } from '@styled-icons/material'
import { PageTitle, Button, DangerButton } from './CommonStyledComponents'


const SettingsContainer = styled.div`
  background: white;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  border: 1px solid #cfcfe6;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 3px;
`

const SettingsSection = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const SettingsSectionTitleContainer = styled.div`
  font-weight: 600;
  font-size: 1.3rem;
  display: block;
  margin-top: 1.2rem;
  margin-bottom: 2.2rem;
  border-bottom: 1px solid #d3d3d3;
  line-height: 0;
`

const SettingsSectionTitle = styled.span`
  background: white;
  padding-right: 0.75rem;
`

const CustomMarginInput = styled.input`
  font-size: 1rem;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  color: #495057;
`

const SettingLabel = styled.span`
  font-weight: 500;
  margin-right: 4px;
`

const SubscriptionIndicator = styled.span`
  color: ${props => props.active ? '#6161D2' : '#8c8c8c' };
  white-space: nowrap;
`

const ActiveIcon = styled(NotificationsActive)`
  vertical-align: bottom;
  font-size: 1.1rem;
  margin-right: 2px;
`

const InactiveIcon = styled(NotificationsOff)`
  vertical-align: bottom;
  font-size: 1.1rem;
  margin-right: 2px;
`

const SubscriptionActiveIndicator = ({ active }) => {
  return (
    <SubscriptionIndicator active={active}>
      {active ? <ActiveIcon size={19} /> : <InactiveIcon size={19} />}
      {active ? 'Tilattu' : 'Ei tilattu'}
    </SubscriptionIndicator>
  )
}

const BgWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
`

const UserSettings = ({ user, onUserDelete, onSubscribe, onCustomMarginSave }) => {
  const [newCustomMargin, setNewCustomMargin] = useState(parseFloat(user.custom_margin).toFixed(2))

  const handleCustomMarginChange = ({ target }) => {
    setNewCustomMargin(target.value)
  }

  const handleCustomMarginSave = () => {
    onCustomMarginSave(newCustomMargin)
  }

  const handleReportSubscribe = async () => {
    onSubscribe()
  }

  const handleDeleteUser = async () => {
    onUserDelete()
  }

  if (user) {
    return (
      <SettingsContainer>
        <PageTitle>Omat asetukset</PageTitle>
        <SettingsSection >
          <SettingsSectionTitleContainer>
            <SettingsSectionTitle>Yleinen</SettingsSectionTitle>
          </SettingsSectionTitleContainer>
          <p>
            <SettingLabel>Sähköpostiosoite: </SettingLabel>
            <span>{user.email}</span>
          </p>
          <p>
            <SettingLabel>Oma marginaali: </SettingLabel>
            <CustomMarginInput type='number' min='0' style={{width: '3.8rem', padding: '6px'}} value={newCustomMargin} onChange={handleCustomMarginChange} /> c/kWh
          </p>
          <Button onClick={handleCustomMarginSave} >Tallenna</Button>
        </SettingsSection>
        <SettingsSection>
          <SettingsSectionTitleContainer>
            <SettingsSectionTitle>Raportit</SettingsSectionTitle>
          </SettingsSectionTitleContainer>
          <p>
            <SettingLabel style={{ marginRight: '7px' }}>Päivittäinen tuntihintaraportti</SettingLabel>
            <SubscriptionActiveIndicator active={user.day_ahead_subscription} />
          </p>
          <p>
            Tilaamalla tuntihintaraportin saat päivittäin noin klo. 16 sähköpostiisi seuraavan päivän tuntihinnat.
          </p>
          <Button onClick={handleReportSubscribe}>{user.day_ahead_subscription ? 'Lopeta tilaus' : 'Tilaa'}</Button>
        </SettingsSection>
        <SettingsSection>
          <SettingsSectionTitleContainer>
            <SettingsSectionTitle>Käyttäjätilin poisto</SettingsSectionTitle>
          </SettingsSectionTitleContainer>
          <p>
            Kun poistat käyttäjätilisi, kaikki tietosi sekä mahdolliset raporttitilaukset poistetaan.
          </p>
          <DangerButton onClick={handleDeleteUser}>Poista tili</DangerButton>
        </SettingsSection>
      </SettingsContainer>
    )
  }
}

export default UserSettings
