import axios from 'axios'

const baseUrl = 'https://api.sahkonhinta.info/auth/login/'

const login = async (email, password) => {
  const res = await axios.post(baseUrl, {email, password})
  return res.data
}

export default { login }
