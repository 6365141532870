import styled from "styled-components"
import { PageTitle } from "./CommonStyledComponents"

const EmailConfirmContainer = styled.div`
  max-width: 600px;
  text-align: center;
  margin: auto;
  line-height: 1.5;
`

const EmailConfirmPrompt = () => (
  <EmailConfirmContainer>
    <PageTitle>Melkein valmista!</PageTitle>
    <p><strong>Vahvista vielä sähköpostiosoitteesi</strong> seuraamalla lähettämämme vahvistusviestin linkkiä, niin pääset kirjautumaan sisään. Huomaathan, että viestin saapumisessa voi kestää hetki, ja se saattaa päätyä roskapostikansioon.</p>
  </EmailConfirmContainer>
)

export default EmailConfirmPrompt
