import styled from "styled-components"

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  text-align: center;
  font-size: 0.7rem;
  color: #8c8c8c;
  border-top: 1px solid #d3d3d3;
  padding-top: 2px;
  background: white;
`

const AppFooter = () => {

  return (
    <Footer>
      &copy; 2022 Sähkönhinta.info | tuki@sahkonhinta.info
    </Footer>
  )
}

export default AppFooter;
