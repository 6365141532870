import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
`

const NotificationBar = styled.div`
  color: white;
  background: #141414e0;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  overflow: hidden;
  padding: 0 7px;
  margin-bottom: 15px;
  max-width: 300px;
  max-height: 100px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: #999 0px 1px 3px;
  text-align: center;
  font-size: 0.8rem;

  ${(props) => props.success && css`
    background: #28a745;
    color: white;
  `}

  ${(props) => props.error && css`
    background: #dc3545;
  `}
`

const Notification = ({ notifications }) => (
  <Wrapper>
    {
      [...notifications]
        .reverse()
        .map((notification) => (
          <NotificationBar error={notification.error} success={notification.success} key={notification.message}>
            <p>{notification.message}</p>
          </NotificationBar>
        ))
    }
  </Wrapper>
)

export default Notification
