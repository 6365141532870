import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import styled from 'styled-components'
import Notification from './components/Notification'
import Navbar from './components/Navbar'
import { LoginForm, RegisterForm } from './components/LoginForm'
import UserSettings from './components/UserSettings'
import EmailConfirmPrompt from './components/EmailConfirmPrompt'
import Dash from './components/dash/Dash'
import AppFooter from './components/AppFooter'
import authService from './services/auth'
import userService from './services/users'


const AppContentDiv = styled.div`
  max-width: 1200px;
  margin: auto;
`

const App = () => {
  const [currUser, setCurrUser] = useState(() => JSON.parse(window.localStorage.getItem('userData')))
  const [token, setToken] = useState(() => JSON.parse(window.localStorage.getItem('token')))
  const [notifications, setNotifications] = useState([])

  const notify = (message, success = false, error = false) => {
    setNotifications([...notifications, ({ message, success, error })])
    setTimeout(() => {
      setNotifications((prevNotifications) => prevNotifications.slice(1))
    }, 4000)
  }

  const login = async ({ email, password }) => {
    try {
      const { user, token} = await authService.login(email, password);
      window.localStorage.setItem('userData', JSON.stringify(user));
      window.localStorage.setItem('token', JSON.stringify(token));
      setCurrUser(user);
      setToken(token);
      notify('Sisäänkirjautuminen onnistui')
      return true
    } catch (e) {
      if (e.response.status === 401) {
        notify('Väärä sähköpostiosoite tai salasana')
      } else {
        notify('Kirjautuminen epäonnistui')
      }
      return false
    }
  }

  const saveCustomMargin = async (newMargin) => {
    try {
      const data = await userService.editUser(currUser.user_id, { custom_margin: newMargin }, token)
      setCurrUser({ ...currUser, data })
      notify('Marginaali tallennettu')
    } catch {
      notify('Marginaalin tallentaminen epäonnistui')
    }
  }

  const logout = () => {
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem('token')
    setCurrUser(null)
    setToken(null)
    notify('Olet nyt kirjautunut ulos')
  }

  const deleteUser = async () => {
    if (window.confirm('Haluatko varmasti poistaa tilisi?')) {
      const deleted = await userService.deleteUser(currUser.user_id, token)
      if (deleted) {
        logout()
      }
    }
  }

  const subscribe = async () => {
    const res = await userService.subscribe(!currUser.day_ahead_subscription, currUser.user_id, token)
    setCurrUser({ ...currUser, day_ahead_subscription: res.day_ahead_subscription })
  }

  console.log(`Current user: ${currUser ? currUser.email : 'none'}`)

  return (
    <>
      <Router>
        <Navbar loggedIn={!!currUser} onLogout={logout} />
        <AppContentDiv>
            <Routes>
              <Route path='*' element={ <Navigate replace to='/' /> } />
              <Route path='/' element={ <Dash user={currUser} /> } />
              <Route path='/analytics' element={ <Navigate replace to='/' /> } />
              <Route path='/settings' element={currUser ? <UserSettings user={currUser} onUserDelete={deleteUser} onSubscribe={subscribe} onCustomMarginSave={saveCustomMargin} /> : <Navigate replace to='/login' />} />
              <Route path='/emailConfirmPrompt' element={!currUser ? <EmailConfirmPrompt /> : <Navigate replace to='/' />} />
              <Route path='/login' element={!currUser ? <LoginForm onLogin={login} notifyFunc={notify} /> : <Navigate replace to='/' />} />
              <Route path='/register' element={!currUser ? <RegisterForm notifyFunc={notify} /> : <Navigate replace to='/' />} />
            </Routes>
        </AppContentDiv>
      </Router>
      <AppFooter />
      <Notification notifications={notifications} />
    </>
  )
}

export default App
