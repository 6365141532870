import { useEffect, useRef, useState } from 'react'

import dayjs from 'dayjs'

import styled, { css, keyframes } from 'styled-components'
import { Info, NavigateBefore, NavigateNext, HourglassTop, Today, ExpandMore } from '@styled-icons/material'

import CircleChart from './CircleChart'
import BarChart from './BarChart'
import React from 'react'
import { Button, MultiSwitchGroup, MultiSwitchLabel, MultiSwitchButtonGroup, MultiSwitchButton, Loader } from '../CommonStyledComponents'

import analyticsService from '../../services/analytics'

const DashComponent = styled.div`
  background: white;
  padding: 1rem;
  margin: 0.5rem;
  border: 1px solid #cfcfe6;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 3px;
  height: 600px;

  @media (max-width: 800px) {
    width: 100%;
    margin: 10px auto;
  }
`

const DashComponentHr = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
  border-bottom: 1px solid rgb(229, 229, 234);

  @media (min-width: 600px) and (max-width: 800px) {
    ${p => p.hideOnMedium && css`
      display: none;
    `}
    margin: 1.5rem 0;
  }
`

const DashContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;

  ${DashComponent}:first-of-type {
    margin-left: 0;
  }
  
  @media (max-width: 800px) {
    flex-wrap: wrap;
    overflow: hidden;
  }

`

const WidgetTitle = styled.h2`
  font-size: 1rem;
  margin: 0.5rem;
  font-weight: 400;
  text-transform: uppercase;
`

const CurrentPriceCalloutSpan = styled.span`
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${p => p.color || '#d3d3d3'};
  margin: 0.5rem;
`

const CurrentWidget = styled(DashComponent)`
  text-align: center;
  min-width: 250px;

  figure {
    margin: auto;
    margin-top: 1rem;
  }

  @media (min-width: 600px) and (max-width: 800px) {
    display: flex;
    height: fit-content;
  }
  @media (max-width: 600px) {
    height: fit-content;
    #right {
      display: ${p => p.expand ? 'block' : 'none'};
    }
  }
`

const CurrentPriceContainer = styled.div`
  padding-top: 1.2rem;
`

const WidgetSection = styled.div`

  @media (min-width: 600px) and (max-width: 800px) {
    width: 50%;
  }

`

const Tooltip = styled.span`
  position: absolute;
  width: 250px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background: #141414e0;
  color: white;
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 5px;
  line-height: 1.5;
  display: block;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #141414e0 transparent;
  }

  @media (hover: none) {
    display: none;
  }
`

const CurrentPriceTooltip = styled(Tooltip)`

`

const CurrentPriceInfoIconContainer = styled.span`
  position: relative;
  padding: 10px;
  margin: -10px;

  ${CurrentPriceTooltip} {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.1s, opacity 0.1s linear;
  }
  @media (hover: hover) {
    &:hover {
      svg {
        color: #141414e0;
      }
      ${CurrentPriceTooltip} {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.1s linear;
      }
    }
  }
  @media (hover: none) {
    display: none;
  }
`

const CurrentPriceInfoIcon = styled(Info)`
  transform: translateY(-0.4rem);
  color: rgb(199, 199, 204);
`

const TodayPriceInfoTable = styled.table`
  width: 100%;
  td {
    padding: 5px 0;
  }
  tr td:first-of-type {
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    margin-right: 1rem;
    text-align: left;
  }
  tr td:last-of-type {
    text-align: right;
    width: 100%
  }

`

const DayPricesWidget = styled(DashComponent)`
  position: relative;
  width: 100%;
  height: 600px;

  ${WidgetTitle} {
    text-align: center;
    height: 25px;

    svg {
      color: hsl(210,8%,45%);
      transform: translateY(-1px);
      padding: 5px;
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          background: #e5e5ea;
          border-radius: 10px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    height: 350px;
  }
`

const colorsByBin = {
  1: 'rgb(48, 209, 88)',
  2: 'rgb(255, 159, 10)',
  3: 'rgb(255, 69, 58)',
}


const stringsByBin = {
  1: {
    title: 'Edullinen',
    desc: 'Tämän tunnin hinta on edullinen suhteessa vuorokauden keskihintaan. Sähköä paljon kuluttavat askareet kannattaa tehdä, kun sähkö on edullista.'
  },
  2: {
    title: 'Normaali',
    desc: 'Tämän tunnin hinta on kohtalainen suhteessa vuorokauden keskihintaan.'
  },
  3: {
    title: 'Kallis',
    desc: 'Tämän tunnin hinta on kallis suhteessa vuorokauden keskihintaan. Sähkön käytttö kannattaa pyrkiä kohdistamaan edullisemmille tunneille.'
  }
}

const HourglassKf = keyframes`
  from {
    transform: rotate(180deg) scale(0.8);
  }
  to {
    transform: rotate(0) scale(1);
  }
`

const MissingDataDiv = styled.div`
  text-align: center;
  width: 500px;
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);

  p {
    line-height: 1.7rem;
  }
  svg {
    padding: 1.2rem;
    margin: -1rem;
    animation: ${HourglassKf} 0.3s forwards;
    animation-delay: 0.2s;
  }
  padding-top: 20px;
`

const MarginLoginTooltip = styled(Tooltip)`
  visibility: hidden;
  bottom: 35px;
  width: 200px;
  white-space: normal;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.1s, opacity 0.1s linear;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #141414e0 transparent transparent  transparent;
  }

`

const MarginOnButton = styled(MultiSwitchButton)`
  position: relative;
  &:hover {
    ${MarginLoginTooltip} {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.1s linear;
    }
  }
`

const ReturnButtonStyle = styled(Button)`
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 7px 10px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  background: white;
  border: none;
  width: 165px;
  height: 35px;
  overflow: hidden;
  color: hsl(210,8%,45%);
  
  #text {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
  }

  #icon {
    position: absolute;
    right: 10px;
    top: 8px;
  }

  @media (hover: hover) {
    &:hover {
      background: #e5e5ea;
      filter: none;
    }
  }
  @media (max-width: 500px) {
    #text {
      display: none;
    }
    width: 35px;
  }
`

function ReturnButton({ onClick }) {
  return (
    <ReturnButtonStyle onClick={onClick}>
      <span id='text'>Palaa nykyhetkeen</span>
      <Today id='icon' size={20} />
    </ReturnButtonStyle>
  )
}

function MissingData() {
  return (
    <MissingDataDiv>
      <HourglassTop size={60} />
      <h2>Tyhjää täynnä</h2>
      <p>Valitsemasi päivän tiedot eivät ole vielä saapuneet sähköpörssistä. Saamme tiedot edellisenä päivänä noin klo 15.</p>
    </MissingDataDiv>
  )
}

function CurrentPriceCallout({ bin }) {
  return (
    <>
      <CurrentPriceCalloutSpan color={bin ? colorsByBin[bin] : 'rgb(229, 229, 234)'} >
        {bin ? stringsByBin[bin].title : 'Ei tiedossa'}
      </CurrentPriceCalloutSpan>
      {bin &&
        <CurrentPriceInfoIconContainer>
          <CurrentPriceInfoIcon size={20} />
          <CurrentPriceTooltip>{stringsByBin[bin].desc}</CurrentPriceTooltip>
        </CurrentPriceInfoIconContainer>
      }
    </>
  )
}

function ProviderOutageNotice({ show }) {
  if (show) {
    return (
      <div style={
        {
          borderRadius: '3px',
          background: 'rgb(255, 69, 58)',
          boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          color: 'white',
          fontWeight: 500,
          padding: '7px 10px',
          marginBlockStart: '1.5rem',
          textAlign: 'center'
        }
      }>
        <p>
          Kantaverkko-operaattoreiden verkoston tiedonvälityksessä esiintyy viiveitä, minkä takia seuraavan vuorokauden hinnat saapuvat palveluun tavallista myöhemmin.
          Pahoittelemme tilanteesta aiheutuvaa haittaa.
        </p>
      </div >
    )
  }
}

const ExpandIcon = styled(ExpandMore)`
  vertical-align: baseline;
  padding: 10px;
  margin: -10px;
  rotate: ${p => p.expanded ? '180deg' : '0deg'};
  color: hsl(210,8%,45%);
  
  @media (min-width: 500px) {
    display: none;
  }
`

const Dash = ({ user }) => {
  const [loading, setLoading] = useState(true)
  const [config, setConfig] = useState({ vat: 0, useCustomMargin: false })
  const [stats, setStats] = useState(undefined)
  const [data, setData] = useState(undefined)
  const [date, setDate] = useState(dayjs().startOf('D'))
  const [expandLeft, setExpandLeft] = useState(false)

  const empty = () => !!data

  const showingToday = () => date.startOf('D').diff(dayjs().startOf('D'), 'd') === 0
  const indexNow = () => dayjs().hour()
  const maxPrice = () => data && Math.max(...data.map(d => d.price)) * 1.1

  const [selectedHourIndex, setSelectedHourIndex] = useState(showingToday() ? indexNow() : 0)

  useEffect(() => {
    async function fetchData() {
      const newData = await analyticsService.getDataForDate(date)
      if (newData && newData.hours.length === 24) {
        calculateGrossPrice(config.vat, 0, newData.hours, newData.stats)
        setSelectedHourIndex(showingToday() ? indexNow() : 0)
      } else {
        setData(null)
      }
      setLoading(false)
    }
    fetchData()
  }, [date])


  function resetSelectedHour() {
    const newIndex = showingToday() ? indexNow() : 0
    setSelectedHourIndex(newIndex)
  }

  function selectHour(i) {
    setSelectedHourIndex(i)
  }

  function calculateGrossPrice(newVat, newMargin, useData, useStats) {
    const multiply = 1 + (newVat / 100)
    const add = newMargin || 0

    const oldData = useData || data
    const oldStats = useStats || stats

    setData(oldData.map(dp => ({ ...dp, grossPrice: dp.price * multiply + add })))
    setStats({
      ...oldStats,
      grossMedian: oldStats.median * multiply + add,
      lowPrice: { ...oldStats.lowPrice, grossPrice: oldStats.lowPrice.price * multiply + add },
      highPrice: { ...oldStats.highPrice, grossPrice: oldStats.highPrice.price * multiply + add }
    })
  }

  function handleVatChange({ target }) {
    const newVat = parseFloat(target.value)
    setConfig({ ...config, vat: newVat })
    calculateGrossPrice(parseInt(newVat))
  }

  function handleCustomMarginToggle({ target }) {
    const useMargin = target.value
    if (user) {
      setConfig({ ...config, useCustomMargin: useMargin })
      calculateGrossPrice(config.vat, useMargin && parseFloat(user.custom_margin))
    }
  }

  function nextDay() {
    setLoading(true)
    setDate(date.add(24, 'hour'))
  }

  function prevDay() {
    setLoading(true)
    setDate(date.subtract(24, 'hour'))
  }

  function setDay(date) {
    setLoading(true)
    setDate(date.startOf('D'))
  }

  return (
    <>
      <ProviderOutageNotice show={false} />
      <DashContainer>
        <CurrentWidget expand={expandLeft}>
          <WidgetSection id='left'>
            <WidgetTitle>Hinta {data && ((showingToday() && selectedHourIndex == indexNow()) ? 'nyt' : data.at(selectedHourIndex).hour)}</WidgetTitle>
            <CircleChart
              size={200}
              percentage={data && (data.at(selectedHourIndex).price / maxPrice()) * 100}
              color={data && colorsByBin[data.at(selectedHourIndex).bin]}
              value={data && data.at(selectedHourIndex).grossPrice}
            />
            <CurrentPriceContainer>
              <CurrentPriceCallout bin={!!data && data.at(selectedHourIndex).bin} />
              <ExpandIcon size={25} expanded={expandLeft} onClick={() => setExpandLeft(!expandLeft)} />
            </CurrentPriceContainer>
          </WidgetSection>
          <WidgetSection id='right'>

            <DashComponentHr hideOnMedium />
            <WidgetTitle>{showingToday() ? 'Tänään' : date.format('D.M.YYYY')}</WidgetTitle>
            <TodayPriceInfoTable>
              <tbody>
                <tr>
                  <td>Keskihinta</td>
                  <td>{!!empty() ? stats.grossMedian.toFixed(2) : '-'} c/kWh</td>
                </tr>
                <tr>
                  <td>Kallein</td>
                  <td>{!!empty() && <span style={{ color: 'hsl(210,8%,45%)' }}>({stats.highPrice.hour})</span>} {!!empty() ? stats.highPrice.grossPrice.toFixed(2) : '-'} c/kWh</td>
                </tr>
                <tr>
                  <td>Edullisin</td>
                  <td>{!!empty() && <span style={{ color: 'hsl(210,8%,45%)' }}>({stats.lowPrice.hour})</span>} {!!empty() ? stats.lowPrice.grossPrice.toFixed(2) : '-'} c/kWh</td>
                </tr>
              </tbody>
            </TodayPriceInfoTable>

            <DashComponentHr />
            <WidgetTitle>Asetukset</WidgetTitle>
            <MultiSwitchGroup>
              <MultiSwitchLabel>
                ALV
              </MultiSwitchLabel>
              <MultiSwitchButtonGroup>
                <MultiSwitchButton type="button" value="0.0" onClick={handleVatChange} selected={config.vat === 0.0} off>0%</MultiSwitchButton>
                <MultiSwitchButton type="button" value="10.0" onClick={handleVatChange} selected={config.vat === 10.0}>10%</MultiSwitchButton>
                <MultiSwitchButton type="button" value="25.5" onClick={handleVatChange} selected={config.vat === 25.5}>25.5%</MultiSwitchButton>
              </MultiSwitchButtonGroup>
            </MultiSwitchGroup>
            <MultiSwitchGroup>
              <MultiSwitchLabel>
                Oma margin.
              </MultiSwitchLabel>
              <MultiSwitchButtonGroup>
                <MultiSwitchButton type="button" value="" onClick={handleCustomMarginToggle} selected={!config.useCustomMargin} off>Pois</MultiSwitchButton>
                <MarginOnButton type="button" value="1" disabled={!user} onClick={handleCustomMarginToggle} selected={!!config.useCustomMargin} >
                  Päällä
                  {!user && <MarginLoginTooltip>Kirjaudu sisään asettaaksesi oman marginaalin</MarginLoginTooltip>}
                </MarginOnButton>
              </MultiSwitchButtonGroup>
            </MultiSwitchGroup>
          </WidgetSection>
        </CurrentWidget>
        <DayPricesWidget style={{ marginRight: 0 }}>
          {!showingToday() && <ReturnButton onClick={() => setDay(dayjs())} />}
          <WidgetTitle>
            <NavigateBefore size={25} onClick={prevDay} />
            <span style={{ padding: '0 20px' }}>{date.format('D.M.YYYY')}</span>
            <NavigateNext size={25} onClick={nextDay} />
          </WidgetTitle>
          <DashComponentHr />

          {
            !loading ?
              !!data ?
                <BarChart
                  data={data}
                  maxPrice={maxPrice()}
                  indexNow={indexNow()}
                  selectedHourIndex={selectedHourIndex}
                  setSelectedHourIndex={selectHour}
                  resetSelectedHourIndex={resetSelectedHour}
                  today={showingToday()}
                />
                :
                <MissingData />
              :
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) scale(0.25)' }}>
                <Loader />
              </div>
          }

        </DayPricesWidget>
      </DashContainer>
    </>
  )
}

export default Dash
