import { useState, useEffect, useRef } from 'react'
import { css, keyframes } from 'styled-components'
import styled from 'styled-components'
import { Button } from '../CommonStyledComponents'

const CircleBg = styled.circle`
  r: ${p => p.r};
  cx: 50%;
  cy: 50%;
  fill: none;
  stroke: rgb(229, 229, 234);
  stroke-width: ${p => p.lineWidth};
  
`

const circleFgKf = keyframes`
  100% {
		stroke-dashoffset: 0;
	}
`

const circleFullKf = keyframes`
  0% {
    transform: scale(1.00);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1.00);
  }

`

const CircleFg = styled.circle`
  r: ${p => p.r};
  cx: 50%;
  cy: 50%;
  fill: none;
  stroke: ${p => p.dashColor};
  stroke-dasharray: ${p => p.cOffset} ${p => p.c};
  stroke-dashoffset: ${p => p.prevCOffset};
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
  stroke-width: ${p => p.lineWidth};

  animation: ${circleFgKf} ${p => p.animDuration}s ease-in-out forwards;
  animation-delay: 0.1s;

`

const Figure = styled.figure`
  width: ${p => p.svgSize}px;
  height: ${p => p.svgSize}px;
  margin: 0;
  position: relative;
  transition: transform 0.1s ease-in-out;
  cursor: default;
  animation: ${p => p.animate && circleFullKf} 0.3s ease-in-out;
  animation-delay: ${p => p.animDelay + 0.1}s;

  svg {
    width: 100%;
    height: 100%;
  }
  &:hover {
    transform: scale(1.02);
  }
`

const Caption = styled.figcaption`
  display: inline-block;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  color: #282828;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(calc(-50% - 10px));
  font-size: calc(${p => p.fontSizeModifier} * 0.24px);
  font-weight: 500;

  &::after {
    content: 'c/kWh';
    color: #8c8c8c;
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(50%);
    font-size: calc(${p => p.fontSizeModifier} * 0.09px);
    transform: translateY(calc(50% * ${p => p.fontSizeModifier / 200}));
    font-weight: 400;
  }
  `

const CircleChart = ({ size, percentage, value, color }) => {
  const svgSize = size
  const lineWidth = svgSize / 8

  const pi = 3.14
  const c = (svgSize - lineWidth) * pi
  const cOffset = percentage / 100 * c
  const r = (svgSize - lineWidth) / 2

  
  const prev = useRef({ c, cOffset }).current
  const prevCOffset = cOffset - prev.cOffset > 0 && cOffset - prev.cOffset || cOffset
  const circleFgAnimDuration = prevCOffset * 0.003
  useEffect(() => {
    prev.c = c
    prev.cOffset = cOffset
  }, [c, cOffset])

  return (
    <>
    <Figure svgSize={svgSize} animate={!!value} animDelay={circleFgAnimDuration} key={cOffset} >
      <svg role="img" xmlns="http://www.w3.org/2000/svg">
        <CircleBg r={r} lineWidth={lineWidth} />
        <CircleFg
          r={r}
          c={c}
          cOffset={cOffset}
          prevCOffset={prevCOffset}
          lineWidth={lineWidth}
          dashColor={color}
          animDuration={circleFgAnimDuration}
          key={cOffset}
        />
      </svg>
      <Caption fontSizeModifier={svgSize} >{value ? value.toFixed(2) : '?'}</Caption>
    </Figure>
    </>
  )
}

export default CircleChart
